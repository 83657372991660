<template>
    <div class="section-other" data-scroll="3" id="other">
        <div class="container">
            <div class="section-other__content">
                <h2 class="section-other__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/other-${$i18n.locale}.png`))" alt="Thank you"></h2>
                <ul class="section-other__list" v-for="(items, index) in $t('otherList')" :key="index">
                    <li>
                        <div class="date">{{ items.date }}:</div>
                        <p class="text">{{ items.text }}</p>
                    </li>
                </ul>
            </div><!--/ .section-news__content -->
        </div><!--/ container -->
    </div>
</template>

<script>
    export default {
        name: "Other"
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-other {
        position: relative;
        max-width: 1920px;
        padding-top: 210px;
        padding-bottom: 200px;
        margin: 0 auto;
        z-index: -1;
        background: {
            image: url("../../../public/img/corporat/bg/bg-leaf01.png"),
            url("../../../public/img/corporat/bg/bg-other-sp-right.png"),
            url("../../../public/img/corporat/bg/bg-other-sp-bottom.png"),
            url("../../../public/img/corporat/bg/bg-other-sp.jpg");
            repeat: no-repeat;
            position: 0 20px, right 75px, right 99%, center bottom;
            size: 40% auto, auto, auto, auto 100%;
        };
        @include media(set-range, $md - 1, $sm) {
            padding-bottom: 230px;
            background-size: auto, auto, auto, cover;
        }
        @include media(set-min-w, $md) {
            background: {
                image: url("../../../public/img/corporat/bg/bg-leaf01.png"),
                url("../../../public/img/corporat/bg/bg-other-sp-right.png"),
                url("../../../public/img/corporat/bg/bg-other.jpg");
                position: 0 60px, right 0, center bottom;
                size: auto, auto, cover;
            }
        }
        @include media(set-min-w, $lg) {
            background-size: 0 0, 0 0, cover;
        }

        &::before {
            /*content: '';*/
            position: absolute;
            top: 0;
            left: 0;
            width: 2032px;
            height: 964px;
            background: url("../../../public/img/corporat/bg/bg-effect.png") no-repeat left top / contain;
        }

        .container {
            max-width: 1360px;
            padding: 0 15px;
            margin: 0 auto;
        }

        &__title {
            margin-bottom: 30px;

            img {
                max-height: 29px;
                @include media(set-min-w, $md) {
                    max-height: none;
                }
            }
        }

        &__content {
            max-width: 1000px;
            padding: 0 30px;
            margin: 0 auto;
            @include media(set-min-w, $sm) {
                padding-right: 0;
                padding-left: 0;
            }
        }

        &__list {
            padding-top: 15px;
            margin-right: 10px;
            margin-left: 10px;
            border-left: 1px solid #fff;
            color: #fff;
            line-height: 18px;
            font-size: 14px;
            @include media(set-min-w, $md) {
                padding-bottom: 10px;
                margin-left: 20px;
            }

            li {
                position: relative;
                padding-left: 30px;
                margin-bottom: 20px;
                @include media(set-min-w, $md) {
                    display: flex;
                    padding-left: 45px;
                }

                &:last-child {
                    margin-bottom: 0;

                    .text {
                        min-height: auto;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: -8px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-color: #ff2700;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    @include media(set-min-w, $md) {
                        width: 18px;
                        height: 18px;
                        left: -9px;
                    }
                }
            }

            .date {
                margin-bottom: 5px;
                font-size: 18px;
                font-weight: 800;
                @include media(set-min-w, $md) {
                    flex: 0 0 200px;
                }
            }

            .text {
                min-height: 36px;
                @include media(set-min-w, $md) {
                    flex-grow: 1;
                }
            }
        }
    }
    .webp {
        .section-other {
            background: {
                image: url("../../../public/img/corporat/bg/bg-leaf01.webp"),
                url("../../../public/img/corporat/bg/bg-other-sp-right.webp"),
                url("../../../public/img/corporat/bg/bg-other-sp-bottom.webp"),
                url("../../../public/img/corporat/bg/bg-other-sp.webp");
            };
            @include media(set-min-w, $md) {
                background: {
                    image: url("../../../public/img/corporat/bg/bg-leaf01.webp"),
                    url("../../../public/img/corporat/bg/bg-other-sp-right.webp"),
                    url("../../../public/img/corporat/bg/bg-other.webp");
                }
            }
            &::before {
                background: url("../../../public/img/corporat/bg/bg-effect.webp") no-repeat left top / contain;
            }
        }
    }

</style>